import { combineReducers } from 'redux';

// Add other reducers here
import Help from './help/Reducer';
import Recents from './recents/Reducer';
import Config from './config/Reducer';
import MenuItems from './menuItems/Reducer';

const Reducers = combineReducers({
	Help,
	Recents,
	Config,
	MenuItems,
});

export default Reducers;
