import moment from 'moment';

import * as FW from 'fw/system/FW';
import * as Context from 'fw/system/Context';
import * as Logging from 'fw/system/Logging';
import * as Notifications from 'fw/globals/Notifications';

import { API, Cache } from 'modules/app';
import * as MenuActions from 'modules/state/menuItems/Actions';
import Store from 'modules/state/Store';

import packageJson from '../../../package.json';

const hookGlobalErrorCatcher = () => {
	// Catch-all errors
	window.onerror = (msg, url, lineNo, columnNo, error) => {
		const message = [
			`Message: ${msg}`,
			`URL: ${url}`,
			`Line: ${lineNo}`,
			`Column: ${columnNo}`,
			`Error object: ${JSON.stringify(error)}`,
		].join(' - ');

		Logging.log(message);

		return false;
	};
};

const removeWarnings = () => {
	// Remove react-data-grid warnings
	const warning = 'Warning: getDefaultProps is only used on classic React.createClass definitions. Use a static property named `defaultProps` instead.';
	// eslint-disable-next-line no-console
	const consoleError = console.error.bind(console);

	// eslint-disable-next-line no-console
	console.error = (...args) => {
		if (args[0] === warning) return;
		// eslint-disable-next-line consistent-return
		return consoleError(...args);
	};
};

export const start = () => {
	// Initialize API pointing to the right URL
	FW.initialize({
		dateFormat: 'DD MMM YYYY', // momentjs format.
		customDateFormat: 'dd MMM yyyy', // date-fns format.
		timeFormat: 'hh:mm:ss A',
		loginRoute: '/public/login',
		fileUploadUrl: API.App.url('/storage/upload'),
		fileDownloadUrl: API.App.url('/storage/directDownload'),
		fileViewUrl: API.App.url('/storage/directView?p='),
		loggingUrl: API.App.url('/logging/log'),
		version: packageJson.version,
		showNews: true,
		showSidebar: true,
		enableGoogleAnalytics: true,
	});

	// Remove unwanted warnings
	removeWarnings();

	// Hook error global catcher
	hookGlobalErrorCatcher();

	if (Context.retrieve()) {
		Store.dispatch(MenuActions.updateMenuItems(Context.retrieve().menuItems));
	}
};

export const context = () => {
	return Context.retrieve();
};

export const login = (r) => {
	Context.store(r);
	Store.dispatch(MenuActions.updateMenuItems(r.menuItems));
	Cache.clear();
};

export const updateUserName = (userName) => {
	Context.set('userName', userName);
};

export const updateAuditUserName = (auditUserName) => {
	Context.set('auditUserName', auditUserName);
};

export const updatePasswordExpirationRemainingDays = (passwordExpirationRemainingDays) => {
	Context.set('passwordExpirationRemainingDays', passwordExpirationRemainingDays);
};

export const logout = () => {
	Context.clear();
};

export const isAuthenticated = () => {
	if (Context.retrieve() == null) {
		return false;
	}

	const sessionTimeout = Context.get('sessionTimeout');
	const lastServerCall = Context.get('lastServerCall');
	const diff = moment().diff(lastServerCall, 'minutes');

	// If last server call was too long ago
	if (diff > sessionTimeout) {
		logout();
		return false;
	}

	return true;
};

export const revertImpersonation = () => {
	API.Security.post('/authentication/revertImpersonation', null,
		{
			success: (sr) => {
				login(sr.data);
				window.location.href = '/';
			},
		});
};

export const impersonate = (userId) => {
	const rq = {
		userId: userId,
	};

	API.Security.post('/authentication/impersonate', rq,
		{
			success: (sr) => {
				login(sr.data);
				window.location.href = '/';
			},
			error: (sr) => {
				Notifications.error(sr.error.errorMessage);
			},
		}, { block: true });
};

export const hasPolicy = (policy) => {
	const policies = Context.get('policies');

	return policies.includes(policy);
};

export const getAvatar = (userId) => {
	return API.Security.url('/query/getAvatar?userId=') + userId;
};
