import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Blocker from 'fw/components/blocker/Blocker';

let _blockRef;
let _modalRef;

export const hookmodal = (ref) => {
	_modalRef = ref;
};

export const hookref = (ref) => {
	_blockRef = ref;
};

export const block = () => {
	_blockRef && _blockRef.update(true);
	_modalRef && _modalRef.update(true);
};

export const unblock = () => {
	_blockRef && _blockRef.update(false);
	_modalRef && _modalRef.update(false);
};

export const Block = forwardRef(({
	blocking,
	children,
}, ref) => {
	const [isBlocking, setIsBlocking] = useState(blocking);

	const handleUpdate = (value) => {
		setIsBlocking(value);
	};

	useImperativeHandle(ref, () => ({
		update: handleUpdate,
	}));

	return (
		<Blocker tag="div" isBlocking={isBlocking}>
			{children}
		</Blocker>
	);
});
