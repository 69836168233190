import * as API from 'modules/app/API';
import { get, reset, clear } from 'fw/system/Cache';

export const Keys = {
	CustomerStatuses: API.Biz.url('/retail/customers/query/getStatuses'),
	CustomerChannels: API.Biz.url('/retail/customers/query/getChannels'),
	HelpInfoTooltips: API.App.url('/query/getTooltips'),
};

export const preload = () => {
	get(Keys.CustomerStatuses);
	get(Keys.HelpInfoTooltips);
};

export {
	get,
	reset,
	clear,
};
