import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import * as BrowserDetection from 'fw/misc/BrowserDetection';

import BrowserNotSupported from 'areas/shared/errors/browserNotSupported/BrowserNotSupported';

import * as App from 'modules/app/App';
import ErrorHandler from 'modules/app/ErrorHandler';
import Store from 'modules/state/Store';
import Routes from 'modules/app/Routes';

// Initiate app
App.start();

let comp = (
	<Provider store={Store}>
		<Router>
			<ErrorHandler>
				<Routes />
			</ErrorHandler>
		</Router>
	</Provider>
);

if (!BrowserDetection.isBrowserSupported()) {
	comp = <BrowserNotSupported />;
}

// Render
render(comp, document.getElementById('root'));
