import React from 'react';
import { Redirect } from 'react-router';

class ErrorHandler extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		return {
			hasError: true,
		};
	}

	render() {
		if (process.env.REACT_APP_ENV !== 'local' && this.state.hasError) {
			return (
				<Redirect to="/unhandledError" />
			);
		}

		return (
			<>
				{this.props.children}
			</>
		);
	}
}

export default ErrorHandler;
