import Store from 'modules/state/Store';
import * as Actions from 'modules/state/help/Actions';

export const setHelp = (helpKey) => {
	Store.dispatch(Actions.setHelp(helpKey));
};

export const clearHelp = (helpKey) => {
	Store.dispatch(Actions.clearHelp(helpKey));
};

// User Setup
export const userSetupHelpKey = 'Admin/UserSetup';
export const userSetupAuditHelpKey = 'Admin/UserSetup/Audit';
export const userSetupInfoHelpKey = 'Admin/UserSetup/Info';

// App Config Setup
export const appConfigSetupHelpKey = 'Admin/AppConfig/Setup';
export const appConfigSettingsHelpKey = 'Admin/AppConfig/Settings';
export const appConfigSecurityHelpKey = 'Admin/AppConfig/Security';
export const appConfigAuditHelpKey = 'Admin/AppConfig/Audit';

// Role Setup
export const roleSetupHelpKey = 'Admin/RoleSetup';
export const roleSetupAuditHelpKey = 'Admin/RoleSetup/Audit';
export const roleSetupInfoHelpKey = 'Admin/RoleSetup/Info';
export const roleAssignedUsersHelpKey = 'Admin/RoleSetup/AssignedUsers';

// News Setup
export const newsSetupHelpKey = 'Admin/NewsSetup';
export const newsSetupAuditHelpKey = 'Admin/NewsSetup/Audit';
export const newsSetupInfoHelpKey = 'Admin/NewsSetup/Info';

// Notification Setup
export const notificationSetupHelpKey = 'Admin/NotificationSetup';
export const notificationSetupAuditHelpKey = 'Admin/NotificationSetup/Audit';
export const notificationSetupInfoHelpKey = 'Admin/NotificationSetup/Info';

// Help Topics Setup
export const helpSetupHelpKey = 'Admin/HelpTopicsSetup';
export const helpSetupAuditHelpKey = 'Admin/HelpTopicsSetup/Audit';
export const helpSetupInfoHelpKey = 'Admin/HelpTopicsSetup/Info';

// Tooltip Setup
export const tooltipSetupHelpKey = 'Admin/TooltipSetup';
export const tooltipSetupAuditHelpKey = 'Admin/TooltipSetup/Audit';
export const tooltipSetupInfoHelpKey = 'Admin/TooltipSetup/Info';

// Emailing Log Setup
export const emailingLogHelpKey = 'Admin/EmailingLog';
export const emailingLogInfoHelpKey = 'Admin/EmailingLog/EmailInfo';

// Email Template Setup
export const emailTemplateSetupHelpKey = 'Admin/EmailTemplate';
export const emailTemplateSetupInfoHelpKey = 'Admin/EmailTemplate/TemplateInfo';

// Audit Review
export const auditReviewHelpKey = 'Admin/AuditReview';

// My Profile
export const userProfileInfoHelpKey = 'Admin/MyProfile/Info';
export const userProfileSecurityHelpKey = 'Admin/MyProfile/Security';

// Demo
export const demoHelpKey = 'Demo/PrimeroDemo';

// Data Import
export const dataImportHelpKey = 'Data/DataImport/Users';

// Report Manager
export const reportManagerSchedulesHelpKey = 'Reports/ReportManager/MySchedules';
export const reportManagerExecutingHelpKey = 'Reports/ReportManager/CurrentlyExecuting';
export const reportManagerHistoryHelpKey = 'Reports/ReportManager/History';

// Users Report
export const userReportSummaryHelpKey = 'Reports/UserReports/UserSummaryReport';

// Dashboard
export const dashboardSupportHelpKey = 'Dashboard/Support';
export const dashboardLogsHelpKey = 'Dashboard/Logs';

// Console
export const consoleHelpKey = '/admin/console';
export const consoleServicesHelpKey = '/admin/console/services';
export const consoleQueuesHelpKey = '/admin/console/queues';
export const consoleCacheHelpKey = '/admin/console/cache';
export const consoleErrorSummaryHelpKey = '/admin/console/errorSummary';
export const consoleLogLookupHelpKey = '/admin/console/logLookup';
export const consoleBookmarksHelpKey = '/admin/console/bookmarks';
export const consoleReIndexHelpKey = '/admin/console/reIndex';
export const consoleSharePointHelpKey = '/admin/console/sharePoint';
export const consoleProcessesHelpKey = '/admin/console/processes';
