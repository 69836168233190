import * as Constants from './Constants';

const initialState = {
	helpKey: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case Constants.SET_HELP:
			return {
				helpKey: action.helpKey,
			};
		case Constants.CLEAR_HELP:
			return {
				helpKey: null,
			};
		default:
			return state;
	}
};

export default Reducer;
