import {
	faUser,
	faQuestionCircle,
	faExclamationCircle,
	faCog,
	faSearch,
	faFileExport,
	faAddressCard,
	faThList,
	faDollarSign,
	faFileImport,
	faUserSecret,
	faTerminal,
	faClock,
	faRss,
} from '@fortawesome/free-solid-svg-icons';
import {
	faEnvelope,
	faFileCode,
} from '@fortawesome/free-regular-svg-icons';

import Store from 'modules/state/Store';
import * as Actions from 'modules/state/recents/Actions';
import * as API from 'modules/app/API';

const recentTypes = [
	{
		recentTypeCode: 'default',
		icon: faClock,
	},
	{
		recentTypeCode: 'user',
		icon: faUser,
	},
	{
		recentTypeCode: 'help',
		icon: faQuestionCircle,
	},
	{
		recentTypeCode: 'news',
		icon: faRss,
	},
	{
		recentTypeCode: 'notification',
		icon: faExclamationCircle,
	},
	{
		recentTypeCode: 'role',
		icon: faCog,
	},
	{
		recentTypeCode: 'search',
		icon: faSearch,
	},
	{
		recentTypeCode: 'import',
		icon: faFileImport,
	},
	{
		recentTypeCode: 'UserSummaryReport',
		icon: faFileExport,
	},
	{
		recentTypeCode: 'myProfile',
		icon: faUser,
	},
	{
		recentTypeCode: 'customer',
		icon: faAddressCard,
	},
	{
		recentTypeCode: 'product',
		icon: faThList,
	},
	{
		recentTypeCode: 'invoice',
		icon: faDollarSign,
	},
	{
		recentTypeCode: 'emailingLog',
		icon: faEnvelope,
	},
	{
		recentTypeCode: 'appConfig',
		icon: faCog,
	},
	{
		recentTypeCode: 'audit',
		icon: faUserSecret,
	},
	{
		recentTypeCode: 'console',
		icon: faTerminal,
	},
	{
		recentTypeCode: 'emailTemplate',
		icon: faFileCode,
	},
];

export const add = (recent) => {
	API.App.post('/recent/saveRecent', recent, {
		success: () => {
			Store.dispatch(Actions.addRecent(recent));
		},
	},
	{ block: false });
};

export const remove = (recent) => {
	Store.dispatch(Actions.removeRecent(recent));
};

export const getRecentTypes = () => {
	return recentTypes;
};

export const getRecentTypeNames = () => {
	return recentTypes.map((r) => r.recentTypeCode);
};

export const getDefaultRecentType = () => {
	return recentTypes[0];
};

export const getRecentTypeAttributes = (typeCode) => {
	let type = typeCode
		&& getRecentTypes().find((t) => {
			return t.recentTypeCode === typeCode;
		});

	if (!type) {
		// No luck?  Get default...
		type = getDefaultRecentType();
	}

	// Clone object and return all attributes except recentTypeCode
	const typeAttributes = { ...type };
	delete typeAttributes.recentTypeCode;

	return typeAttributes;
};
