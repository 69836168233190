import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import Reducers from './Reducers';
import setupMiddleware from './setup/Middleware';

const Store = createStore(
	Reducers,
	composeWithDevTools(
		applyMiddleware(setupMiddleware),
	),
);

export default Store;
