import * as Constants from './Constants';

export const setHelp = (helpKey) => ({
	type: Constants.SET_HELP,
	helpKey,
});

export const clearHelp = () => ({
	type: Constants.CLEAR_HELP,
});
