let _alertRef;
let _authenticationRef;
let _confirmRef;
let _errorRef;
let _inlineRef;
let _removeRef;
let _unsavedRef;
let _jsonRef;

// Private
const showError = (message) => {
	// eslint-disable-next-line no-console
	console.error('Modal thrown but modals framework was not loaded.', message);
	return false;
};

// Hooks
export const hookAlert = (alertRef) => {
	_alertRef = alertRef;
};

export const hookAuthentication = (authenticationRef) => {
	_authenticationRef = authenticationRef;
};

export const hookConfirm = (confirmRef) => {
	_confirmRef = confirmRef;
};

export const hookError = (errorRef) => {
	_errorRef = errorRef;
};

export const hookInline = (inlineRef) => {
	_inlineRef = inlineRef;
};

export const hookRemove = (removeRef) => {
	_removeRef = removeRef;
};

export const hookUnsaved = (unsavedRef) => {
	_unsavedRef = unsavedRef;
};

export const hookJson = (jsonRef) => {
	_jsonRef = jsonRef;
};

// Functions
export const alert = (message, onClose) => {
	if (!_alertRef) {
		return showError(message);
	}

	_alertRef.open(message, onClose);
	return true;
};

export const authentication = (userId, onClose) => {
	if (!_authenticationRef) {
		return showError(userId);
	}

	_authenticationRef.open(userId, onClose);
	return true;
};

export const confirm = (question, yes, no) => {
	if (!_confirmRef) {
		return showError(question);
	}

	_confirmRef.open(question, yes, no);
	return true;
};

export const error = (content) => {
	if (!_errorRef) {
		return showError(content);
	}

	_errorRef.open(content);
	return true;
};

export const inline = (content, onClose) => {
	if (!_inlineRef) {
		return showError(content);
	}

	_inlineRef.open(content, onClose);
	return true;
};

export const remove = (params) => {
	if (!_removeRef) {
		return showError(params.title);
	}

	_removeRef.open(params);
	return true;
};

export const unsaved = (question, yes, no) => {
	if (!_unsavedRef) {
		return showError(question);
	}

	_unsavedRef.open(question, yes, no);
	return true;
};

export const json = (message, onClose) => {
	if (!_jsonRef) {
		return showError(message);
	}

	_jsonRef.open(message, onClose);
	return true;
};
