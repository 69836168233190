import FWAPI from 'fw/system/API';

const CACHE_PREFIX = 'cache';

export const get = (key, callback) => {
	if (!key) {
		// eslint-disable-next-line no-console
		console.error('Invalid cache key', key);
		return;
	}

	const cacheKey = `${CACHE_PREFIX}:${key}`;

	const data = JSON.parse(localStorage.getItem(cacheKey));
	const url = key;

	if (data && callback) {
		callback(data);
		return;
	}

	new FWAPI().get(key, {}, {
		success: (rs) => {
			// eslint-disable-next-line no-console
			console.log('Cached', url, rs);
			localStorage.setItem(cacheKey, JSON.stringify(rs));
			callback && callback(rs);
		},
	}, { block: false });
};

export const clear = () => {
	const items = [];

	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);

		// Check if it is a cache key
		if (key.split(':')[0] === CACHE_PREFIX) {
			items.push(key);
		}
	}

	// Remove every cache item
	for (let i = 0; i < items.length; i++) {
		localStorage.removeItem(items[i]);
	}

	// eslint-disable-next-line no-console
	console.log('Cache Cleared');
};

export const reset = (key) => {
	if (!key) {
		// eslint-disable-next-line no-console
		console.error('Invalid cache key', key);
		return;
	}

	const cacheKey = `${CACHE_PREFIX}:${key}`;

	// eslint-disable-next-line no-console
	console.log('Cache Reset', key);
	localStorage.removeItem(cacheKey);
};
