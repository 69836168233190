let _settings = null;

export const initialize = (settings) => {
	// Save settings
	_settings = settings;
};

export const getSettings = () => {
	return _settings;
};

export const set = (value) => {
	_settings = Object.assign(_settings, value);
};
