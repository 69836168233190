export const notFoundError = '*';
export const dashboard = '/';
export const search = '/search';
export const recents = '/recents';
export const impersonation = '/impersonation';
export const profile = '/profile';
export const demo = '/demo';

export const appConfig = '/admin/appConfig';
export const userSetup = '/admin/userSetup';
export const roleSetup = '/admin/roleSetup';
export const newsSetup = '/admin/newsSetup';
export const helpSetup = '/admin/helpSetup';
export const auditReview = '/admin/auditReview';
export const console = '/admin/console';

export const invoiceSetup = '/retail/invoices';
export const customerSetup = '/retail/customers';
export const productSetup = '/retail/products';

export const login = '/public/login';
export const resetPassword = '/public/resetPassword';
export const restorePassword = '/public/restorePassword';
export const userRegistration = '/public/userRegistration';
export const expiredPassword = '/public/expiredPassword';
