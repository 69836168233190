const _agent = [
	navigator.platform,
	navigator.userAgent,
	navigator.appVersion,
	navigator.vendor,
	window.opera,
].join(' ');

const _dataOs = [
	{ name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
	{ name: 'Windows', value: 'Win', version: 'NT' },
	{ name: 'iPhone', value: 'iPhone', version: 'OS' },
	{ name: 'iPad', value: 'iPad', version: 'OS' },
	{ name: 'Kindle', value: 'Silk', version: 'Silk' },
	{ name: 'Android', value: 'Android', version: 'Android' },
	{ name: 'PlayBook', value: 'PlayBook', version: 'OS' },
	{ name: 'BlackBerry', value: 'BlackBerry', version: '/' },
	{ name: 'Macintosh', value: 'Mac', version: 'OS X' },
	{ name: 'Linux', value: 'Linux', version: 'rv' },
	{ name: 'Palm', value: 'Palm', version: 'PalmOS' },
];

const _dataBrowser = [
	{ name: 'Edge', value: 'Edge', version: 'Edge' },
	{ name: 'Chrome', value: 'Chrome', version: 'Chrome' },
	{ name: 'Firefox', value: 'Firefox', version: 'Firefox' },
	{ name: 'CriOS', value: 'CriOS', version: 'CriOS' },
	{ name: 'Safari', value: 'Safari', version: 'Version' },
	{ name: 'Internet Explorer', value: 'MSIE', version: 'rv' },
	{ name: 'Internet Explorer', value: 'Trident', version: 'rv' },
	{ name: 'Opera', value: 'Opera', version: 'Opera' },
	{ name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
	{ name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
];

const _supportedMinVersions = [
	// { name: 'Internet Explorer', version: 10 },
	{ name: 'Edge', version: 12 },
	{ name: 'Firefox', version: 21 },
	{ name: 'Chrome', version: 23 },
	{ name: 'Safari', version: 6 },
	{ name: 'CriOS', version: 70 },
	// { name: 'Opera', version: 15 },
	// { name: 'BlackBerry', version: 10 },
];

const matchItem = (string, data) => {
	let name = null;
	let version = null;

	data.some((item) => {
		// Eval the name of the browser in the UserAgent string
		const regexValue = new RegExp(item.value, 'i');
		const match = regexValue.test(string);

		let versions;

		// Take the first match (some browsers show other browser names on their UserAgent string)
		// To solve this the dataBrowser list is ordered
		if (match) {
			name = item.name;

			// Extract the Version from the UserAgent
			const regexVersion = new RegExp(`${item.version}[- /:;]([\\d._]+)`, 'i');

			versions = string.match(regexVersion);
			version = '';

			// Take the first version next to the browser name
			if (versions) {
				if (versions[1]) {
					versions = versions[1];
				}

				versions = versions.split(/[._]+/);

				versions.map((ver, j) => {
					version += ver;

					if (j === 0) {
						version += '.';
					}

					return null;
				});
			} else {
				version = '0';
			}

			return true;
		}

		return false;
	});

	return {
		name: name,
		version: version,
	};
};

export const isBrowserSupported = () => {
	const browser = matchItem(_agent, _dataBrowser);

	// Seek the static list of min supported browsers by browser name
	const min = _supportedMinVersions.find((x) => {
		return x.name === browser.name;
	});

	return min && browser.version >= min.version;
};

export const getBrowserInformation = () => {
	return matchItem(_agent, _dataBrowser);
};

export const getOSInformation = () => {
	return matchItem(_agent, _dataOs);
};

export const getSupportedBrowsers = () => {
	return _supportedMinVersions;
};
