const CONTEXT_KEY = 'context';

// We'll be storing in local storage
const _store = localStorage;

// Context by default is null
let _context = null;

// Storage handling
export const retrieve = () => {
	// We only retrieve from storage if it's not on the var
	if (_context === null) {
		_context = JSON.parse(_store.getItem(CONTEXT_KEY));
	}

	return _context;
};

export const store = (context) => {
	_store.setItem(CONTEXT_KEY, JSON.stringify(context));
	_context = context;
};

export const clear = () => {
	_store.removeItem(CONTEXT_KEY);
};

export const get = (key) => {
	const context = retrieve();

	if (context == null) {
		return null;
	}

	return context[key];
};

export const set = (key, value) => {
	const context = retrieve();

	if (context == null) {
		return;
	}

	context[key] = value;

	store(context);
};

export const remove = (key) => {
	set(key, null);
};
