import * as Constants from './Constants';

const initialState = {
	fullscreen: false,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case Constants.TOGGLE_FULLSCREEN:
			return {
				fullscreen: action.flag,
			};
		default:
			return state;
	}
};

export default Reducer;
