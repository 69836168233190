/* eslint-disable max-classes-per-file */
import * as Context from 'fw/system/Context';
import * as FW from 'fw/system/FW';
import * as Block from 'fw/globals/Block';
import * as Modals from 'fw/globals/Modals';
import * as Guid from 'fw/misc/Guid';
import * as Logging from 'fw/system/Logging';

const _defaultOptions = {
	block: true,
};

class ConsoleAPIDefaultLog {
	log = (method, url, queryString, body) => {
		// eslint-disable-next-line no-console
		console.log(`${method} request`, url + queryString, body);
	}

	logError = (body) => {
		// eslint-disable-next-line no-console
		console.log('Error Info', body);
	}
}

class ConsoleAPISensitiveLog {
	log = (method, url, queryString) => {
		// eslint-disable-next-line no-console
		console.log(method, url + queryString, 'Sensitive Data');
	}

	logError = () => {
		// eslint-disable-next-line no-console
		console.log('Error Info', 'Sensitive Data');
	}
}

class API {
	constructor(serviceName, endpoint) {
		this.serviceName = serviceName;
		this.endpoint = endpoint || '';
	}

	url = (url) => {
		return this.endpoint + url;
	}

	post = (url, payload, callbacks, options) => {
		return this.call(url, 'POST', payload, callbacks, options);
	}

	get = (url, payload, callbacks, options) => {
		return this.call(url, 'GET', payload, callbacks, options);
	}

	call = (url, method, payload, callbacks, options) => {
		// Get JWT token from the storage
		const token = Context.get('token');
		const trace = Guid.generate();

		// Add content & auth headers
		const headers = {
			'X-PSX-TRACE': trace,
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		// Use default options
		let opts = { ..._defaultOptions };

		if (options) {
			opts = Object.assign(opts, options);
		}

		if (opts.block) {
			Block.block();
		}

		let consoleLogger = new ConsoleAPIDefaultLog();
		if (opts.sensitive) {
			consoleLogger = new ConsoleAPISensitiveLog();
		}

		let body;
		let queryString = '';
		const cacheKey = '';

		if (method === 'POST') {
			body = JSON.stringify(payload);
		} else if (method === 'GET') {
			queryString = `?${this.toQueryString(payload)}&${cacheKey}`;
		}

		const requestedUri = this.endpoint + url + queryString;

		// Log
		consoleLogger.log(`${method} request`, url, queryString, body);

		// Using fetch
		return fetch(requestedUri, {
			method: method,
			headers: headers,
			body: body,
		})
			.then((response) => {
				if (opts.block) {
					Block.unblock();
				}

				// We only parse response if status was OK
				if (response.ok) {
					const contentType = response.headers.get('content-type');

					if (contentType === 'application/pdf') return { file: response.blob() };

					return response.json();
				}

				// Unauthorized? Go to login...
				if (response.status === 401) {
					window.location.href = FW.getSettings().loginRoute;
					return null;
				}

				// Forbidden? Go to login...
				if (response.status === 403) {
					Modals.alert('You do not have permissions to access', () => {
						window.location.href = FW.getSettings().loginRoute;
					});
					return null;
				}

				// For network errors and alike, we'll capture the
				// fields and throw a new exception
				const err = {
					status: response.status,
					statusText: response.statusText,
					url: response.url,
				};

				// Server error?
				if (response.status === 500) {
					response.json().then((parsedBody) => {
						err.body = parsedBody;
						consoleLogger.logError(parsedBody);
					});
				}

				throw err;
			})
			.then((json) => {
				// We added this after placing the login redirect
				if (json === null) {
					return null;
				}

				// Log
				consoleLogger.log(`${method} response`, url, queryString, json);

				// Reset last server call counter
				Context.set('lastServerCall', new Date());

				if (json.file) {
					json.file.then((blob) => {
						const href = URL.createObjectURL(blob);
						window.open(href);
					});
					return callbacks.success(json.file);
				}

				// If the service response was error, call the required callbacks
				// Same if it was sucessfull
				if (!json.status) {
					if (callbacks !== undefined && callbacks.error !== undefined) {
						return callbacks.error(json);
					}
				} else if (callbacks !== undefined && callbacks.success !== undefined) {
					return callbacks.success(json);
				}

				return null;
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error('Exception', err, trace);

				if (opts.block) {
					Block.unblock();
				}

				// Log
				if (!err.status || err.status < 500) {
					Logging.error(err, trace);
				}

				if (callbacks !== undefined && callbacks.exception !== undefined) {
					callbacks.exception(err);
				} else {
					Modals.error({ trace, method, err });
				}
			});
	}

	download = (file) => {
		this.post(
			FW.getSettings().fileDownloadUrl,
			{
				storageToken: file.storageToken,
				fileName: file.fileName,
			},
			{
				success: (r) => {
					const a = document.createElement('a');
					a.href = r.data.directLink;
					a.download = file.fileName;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				},
				error: (r) => {
					this.props.onError(r.error);
				},
			},
			{
				block: false,
			},
		);
	}

	raw = (url, payload, callbacks) => {
		// Get JWT token from context
		const token = Context.get('token');

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		const requestedUri = this.endpoint + url;
		return fetch(requestedUri, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(payload),
		})
			.then((response) => {
				if (!response) {
					if (callbacks !== undefined && callbacks.error !== undefined) {
						return callbacks.error(response);
					}
				} else if (callbacks !== undefined && callbacks.success !== undefined) {
					return callbacks.success(response);
				}

				return null;
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error('Exception', err);

				// Log
				const errorMessage = `POST request from API.raw() to ${requestedUri} got: ${err}`;
				Logging.error(errorMessage, null);

				if (callbacks !== undefined && callbacks.exception !== undefined) {
					callbacks.exception(err);
				} else {
					Modals.error();
				}
			});
	}

	upload = (url, formData, callbacks) => {
		// Get JWT token from the storage
		const token = Context.get('token');

		// Add content & auth headers
		const headers = {
			Authorization: `Bearer ${token}`,
		};

		// Using fetch
		const requestedUri = this.endpoint + url;
		return fetch(requestedUri, {
			method: 'POST',
			headers: headers,
			body: formData,
		})
			.then((response) => {
				// We only parse response if status was OK
				if (response.ok) {
					return response.json();
				}

				// For network errors and alike, we'll capture the
				// fields and throw a new exception
				const err = {
					status: response.status,
					statusText: response.statusText,
					url: response.url,
				};

				throw err;
			})
			.then((json) => {
				// If the service response was error, call the required callbacks
				// Same if it was sucessfull
				if (!json.status) {
					if (callbacks.error !== undefined) {
						return callbacks.error(json);
					}
				} else if (callbacks.success !== undefined) {
					return callbacks.success(json);
				}

				return null;
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error('Exception', err);

				// Log
				const errorMessage = `POST request from API.upload() to ${requestedUri} got: ${err}`;
				Logging.error(errorMessage, null);

				if (callbacks.exception !== undefined) {
					callbacks.exception(err);
				} else {
					Modals.error();
				}
			});
	}

	toQueryString = (o) => {
		if (o === undefined || o === null) {
			return '';
		}

		return Object
			.keys(o)
			.filter((key) => o[key] !== null)
			.map((key) => {
				if (Array.isArray(o[key])) {
					return (Array.from(o[key]))
						.map((v) => {
							return `${key}=${v}`;
						})
						.join('&');
				}

				return `${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`;
			})
			.join('&');
	}
}

export default API;
