import * as Context from 'fw/system/Context';
import * as Guid from 'fw/misc/Guid';
import * as FW from './FW';

// Events key name in storage
const EVENTS_KEY = 'logEvents';

// Log source name
const SOURCE_NAME = 'psx-client';

// Interval to wait for next retry
const LOG_POST_RETRY_INTERVAL = 60000;

// Retries to post logs
const LOG_POST_RETRIES = 10;

// Limits the qty of posted events to the log
const LOG_MAX_EVENTS = 5;

// We'll be storing in local storage
const _store = localStorage;

// Sending status flag
let sendingLogs = false;

// Delay function, used to retry sending logs
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Get errors from stack
export const getErrorEvents = () => {
	return JSON.parse(_store.getItem(EVENTS_KEY));
};

// Clear events present in args
const clearErrorEvents = (eventsSent) => {
	const allEvents = getErrorEvents();
	const toPersist = allEvents.filter((evt) => {
		let persist = true;
		// eslint-disable-next-line no-restricted-syntax
		for (const i in eventsSent) {
			if (evt.trace === eventsSent[i].trace) {
				persist = false;
				break;
			}
		}
		return persist;
	});

	// only keep the ones not sent
	_store.setItem(EVENTS_KEY, JSON.stringify(toPersist));
};

// Creates the payload to post the errors
const createPayload = () => {
	return { items: getErrorEvents() };
};

// Function to post logs (with retry)
const fetchWithRetry = async (n) => {
	// Get JWT token from the storage
	const token = Context.get('token');
	const trace = Guid.generate();

	// Add content & auth headers
	const headers = {
		'X-PSX-TRACE': trace,
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	};

	try {
		const payload = createPayload();

		const response = await fetch(FW.getSettings().loggingUrl, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(payload),
		});

		// Clear events from local stack already
		clearErrorEvents(payload.items);

		return response;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
		if (n === 1) throw err;
		await delay(LOG_POST_RETRY_INTERVAL);
		return fetchWithRetry(n - 1);
	}
};

// Returns a smaller version of Context
const getLoggingContext = (asJson) => {
	// clone context
	const basicContext = { ...Context.retrieve() };
	// keep only basic keys
	delete basicContext.privileges;
	delete basicContext.menuItems;
	return asJson ? JSON.stringify(basicContext) : basicContext;
};

// Handles the post
const postLogs = async () => {
	sendingLogs = true;

	const result = fetchWithRetry(LOG_POST_RETRIES);

	result.then(() => {
		// Logs sent
		sendingLogs = false;
	}).catch(() => {
		// Logs failed to be sent
		sendingLogs = false;
	});
};

// Adds an error to the stack
const registerErrorEvent = (error) => {
	const logEvents = getErrorEvents() || [];

	// add error source & context
	error.source = SOURCE_NAME;
	error.context = getLoggingContext(false);

	logEvents.push(error);

	_store.setItem(EVENTS_KEY, JSON.stringify(logEvents.slice(-(LOG_MAX_EVENTS))));

	if (!sendingLogs) {
		postLogs();
	}
};

// Logs an error
export const error = (err, trace) => {
	let errorMessage = '';

	// Log error
	if (typeof (err) === 'string') {
		errorMessage = err;
	} else if (err.status) {
		errorMessage = `${err.status} - ${err.statusText} - ${err.url}`;
	} else {
		errorMessage = err.message;
	}

	if (trace === null) {
		trace = Guid.generate();
	}

	registerErrorEvent({ type: 'Error', message: errorMessage, trace: trace });
};

// Logs a message
export const log = (message) => {
	registerErrorEvent({ type: 'Info', message: message, trace: Guid.generate() });
};
