import React from 'react';

import * as BrowserDetection from 'fw/misc/BrowserDetection';

const BrowserNotSupported = () => {
	const buildSupportedList = () => {
		const supportedBrowsers = BrowserDetection.getSupportedBrowsers().map((browser, index) => {
			return (
				<li key={index} style={{ listStylePosition: 'inside' }}>
					{`${browser.name} (v${browser.version} +)`}
				</li>
			);
		});

		return (<ul>{supportedBrowsers}</ul>);
	};

	const browserInformation = BrowserDetection.getBrowserInformation();

	document.body.style.backgroundColor = 'white';
	document.getElementById('root').style.backgroundColor = 'white';

	return (
		<>
			<div style={{ textAlign: 'center' }}>
				<h2 style={{ backgroundColor: 'white' }}>Your web browser is not supported</h2>
				<br />
				<p style={{ fontSize: '15px' }}>
					We are sorry, but it seems that the web browser you are trying to use to navigate this
					{' '}
					website is no longer supported. Please try again using a different web browser.
				</p>
				<p>
					<strong>Your current web browser is:</strong>
					{` ${browserInformation.name} (v${browserInformation.version})`}
				</p>
				<p>See the supported browser list below:</p>
				{buildSupportedList()}
			</div>
		</>
	);
};

export default BrowserNotSupported;
