let _toastrRef;

export const hookToastr = (toastrRef) => {
	_toastrRef = toastrRef;
};

export const error = (err) => {
	_toastrRef.error(err);
};

export const success = (message) => {
	_toastrRef.success(message);
};
