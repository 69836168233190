import * as Recents from 'modules/app/Recents';
import * as Constants from 'fw/pages/newSetup/SetupPage/state/Constants';

// eslint-disable-next-line no-unused-vars
const middleware = (store) => (next) => (action) => {
	if (action.type === Constants.SAVE_RECENT && action.recent) {
		Recents.add(action.recent);
	}

	if (action.type === Constants.DELETE_RECENT && action.recent) {
		Recents.remove(action.recent);
	}

	return next(action);
};

export default middleware;
