import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

// Roots
const MainLayout = React.lazy(() => import('layouts/main/MainLayout'));
const PublicLayout = React.lazy(() => import('layouts/public/PublicLayout'));
const ReportLayout = React.lazy(() => import('layouts/report/ReportLayout'));

const Routes = () => (
	<Suspense fallback={<div> </div>}>
		<Switch>
			<Route path="/public" component={PublicLayout} />
			<Route path="/reportTemplates" component={ReportLayout} />
			<Route path="/" component={MainLayout} />
		</Switch>
	</Suspense>
);

export default Routes;
