import API from 'fw/system/API';

export const Biz = new API('PSX.Biz', `${process.env.REACT_APP_CORE_API_URL}/biz`);
export const App = new API('PSX.App', `${process.env.REACT_APP_CORE_API_URL}/app`);
export const Security = new API('PSX.Security', `${process.env.REACT_APP_CORE_API_URL}/security`);

export const Core = new API('PSX.Core', process.env.REACT_APP_CORE_API_URL);
export const Emailing = new API('PSX.Emailing', process.env.REACT_APP_EMAILING_API_URL);
export const Audit = new API('PSX.Audit', process.env.REACT_APP_AUDIT_API_URL);
export const Reporting = new API('PSX.Reporting', process.env.REACT_APP_REPORTING_API_URL);
export const Import = new API('PSX.Import', process.env.REACT_APP_IMPORT_API_URL);
export const Search = new API('PSX.Search', process.env.REACT_APP_SEARCH_API_URL);
export const Viz = new API('PSX.Viz', process.env.REACT_APP_VIZ_API_URL);
export const Job = new API('PSX.Job', process.env.REACT_APP_JOB_API_URL);
