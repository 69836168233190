import React from 'react';
import classnames from 'classnames';

const Blocker = ({
	isBlocking,
	children,
}) => {
	if (!isBlocking) {
		return (
			<div>
				{children}
			</div>
		);
	}

	return (
		<div
			className={classnames({
				'block-ui': isBlocking,
			})}
		>
			{children}
			<div className="block-ui-container">
				<div className="block-ui-overlay" />
				<div className="block-ui-message-container">
					<div className="block-ui-message">
						<div className="loading-indicator">
							<span className="loading-bullet">•</span>
							<span className="loading-bullet">•</span>
							<span className="loading-bullet">•</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Blocker;
