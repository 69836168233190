import * as Constants from './Constants';

export const addRecent = (recent) => ({
	type: Constants.ADD_RECENT,
	recent,
});

export const removeRecent = (recent) => ({
	type: Constants.REMOVE_RECENT,
	recent,
});
