import * as Constants from './Constants';

const initialState = {
	recent: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case Constants.ADD_RECENT:
			return {
				recent: action.recent,
			};
		case Constants.REMOVE_RECENT:
			return {
				recent: {
					...action.recent,
					remove: true,
				},
			};
		default:
			return state;
	}
};

export default Reducer;
