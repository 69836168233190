import * as Constants from './Constants';

const initialState = {
	menuItems: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case Constants.UPDATE_MENU_ITEMS:
			return {
				menuItems: action.menuItems,
			};
		default:
			return state;
	}
};

export default Reducer;
